define(['knockout', 'freeze-table'], function (ko) {

    var commentNodesHaveTextProperty = document.createComment("test").text === "<!--test-->";
    var startCommentRegex = commentNodesHaveTextProperty ? /^<!--\s*ko(?:\s+(.+\s*\:[\s\S]*))?\s*-->$/ : /^\s*ko(?:\s+(.+\s*\:[\s\S]*))?\s*$/;
    var endCommentRegex = commentNodesHaveTextProperty ? /^<!--\s*\/ko\s*-->$/ : /^\s*\/ko\s*$/;

    function isStartComment(node) {
        return (node.nodeType == 8) && (commentNodesHaveTextProperty ? node.text : node.nodeValue).match(startCommentRegex);
    }

    function isEndComment(node) {
        return (node.nodeType == 8) && (commentNodesHaveTextProperty ? node.text : node.nodeValue).match(endCommentRegex);
    }

    function traverseNode(node, func) {
        func(node);
        node = node.firstChild;
        while (node) {
            traverseNode(node, func);
            node = node.nextSibling;
        }
    }

    function removeDataBindings(element) {
        var koComments = [];

        traverseNode(element, function (node) {
            if (isStartComment(node) || isEndComment(node)) {
                koComments.push(node);
                return;
            }
            //remove the 'data-bind' attributes
            if (node.nodeType === 1) { //ELEMENT_NODE
                node.removeAttribute('data-bind');
            }
        });

        //remove Knockout binding comments
        for (i = 0; i < koComments.length; i++) {
            node = koComments[i];
            if (node.parentNode) {
                node.parentNode.removeChild(node);
            }
        }
    }


    window.FreezeTable.prototype.clone = function (element) {

        var $clone = $(element).clone()
            .removeAttr('id') // Remove ID

        ko.cleanNode($clone[0]);

        removeDataBindings($clone[0]);

        // Bootstrap background-color transparent problem
        if (this.backgroundColor) {
            $clone.css('background-color', this.backgroundColor);
        }
        return $clone;

    }

});