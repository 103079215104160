define(["require", "exports", "knockout"], function (require, exports, ko) {
	var StringTemplateEngine = (function () {
	    var templates = ko.templates || {},
			templatesCache = ko.templatesCache || {},
			data = {},
			engine = new ko.nativeTemplateEngine();

		ko.templateSources.stringTemplate = function (templateName, template) {
			this.templateName = templateName;
			this.html = template;
		};

		ko.utils.extend(ko.templateSources.stringTemplate.prototype,
		{
			data: function (key, value) {
				data[this.templateName] = data[this.templateName] || {};

				if (arguments.length === 1) {
					return data[this.templateName][key];
				}

				data[this.templateName][key] = value;
			},

			nodes: function(){
				if (arguments.length === 0) {

					var preCompiledTemplate = templatesCache[this.templateName];
					if(preCompiledTemplate){
						return preCompiledTemplate;
					}

					var template = templates[this.templateName];

					if (typeof (template) !== "undefined") {
						var parsedNodes = ko.utils.parseHtmlFragment(template);

						var compiledNodes = document.createElement("div");
						for (var i = 0; i < parsedNodes.length; i++) {
							compiledNodes.appendChild(parsedNodes[i]);
						}
						templatesCache[this.templateName] = compiledNodes;
						return compiledNodes;
					}
				}
				return null;
			},

			text: function (value) {
				if (this.html) {
					return this.html;
				}

				if (arguments.length === 0) {
					var template = templates[this.templateName];

					if (typeof (template) === "undefined") {
						console.log("Template not found: " + this.templateName);
						throw Error("Template not found: " + this.templateName);
					}

					return template;
				}

				templates[this.templateName] = value;
			}
		});

		engine.renderTemplate = function (template, bindingContext, options, templateDocument) {
			var templateSource = this.makeTemplateSource(template, templateDocument, bindingContext, options);
			return this.renderTemplateSource(templateSource, bindingContext, options);
		}

		engine.makeTemplateSource = function (template, templateDocument, bindingContext, options) {
			var elem;
			if (typeof template === "string") {
				elem = (templateDocument || document).getElementById(template);

				if (elem) {
					return new ko.templateSources.domElement(elem);
				}

				return new ko.templateSources.stringTemplate(template);
			}
			else if (options && options.html) {
				// String template
				var rawHtml = '';
				if (options.html instanceof Function) {
					rawHtml = options.html();
				} else {
					rawHtml = options.html;
				}
				return new ko.templateSources.stringTemplate(template, rawHtml);

			} else if (template && (template.nodeType == 1) || (template.nodeType == 8)) {
				return new ko.templateSources.anonymousTemplate(template);
			}
		};

		ko.templates = templates;
		ko.templatesCache = templatesCache;
		return engine;
	})();

	exports.StringTemplateEngine = StringTemplateEngine;
});